<template>
  <div class="app-container">
    <eHeader  :query="query" :bsysoptions="listBSysOptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="productId" label="ID"/>
      <el-table-column prop="productCode" label="产品名称"/>
      <el-table-column prop="productName" label="产品编码"/>
      <el-table-column prop="bsysCode" label="业务系统编号"/>
      <el-table-column prop="bsysName" label="业务系统名称"/>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','MERCHANT_ALL','MERCHANT_EDIT'])" :data="scope.row" :sup_this="sup_this" :bsysoptions="listBSysOptions"/>
          <el-popover
            v-if="checkPermission(['ADMIN','USER_ALL','USER_DELETE'])"
            :ref="scope.row.productId"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.productId].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="softDelete(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initSmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/sms/productinfo/header'
import edit from '@/components/sms/productinfo/edit'
import { updateProduct, listProduct, listAllBusinessSys } from '@/api/sms'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      listBSysOptions: []
    }
  },
  created() {
    this.queryBusinessSysOption()
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/jrqsms/api/product/listProduct'
      const query = this.query
      const productName = query.productName
      const productCode = query.productCode
      this.formData = { currentPage: this.page, pageSize: this.size }
      if (productName !== '' && productName !== null) { this.formData['productName'] = productName }
      if (productCode !== '' && productCode !== null) { this.formData['productCode'] = productCode }
      this.params = JSON.stringify(this.formData)
      return true
    },
    queryBusinessSysOption(){
     const params = JSON.stringify()
     listAllBusinessSys(params).then(res => {
        this.listBSysOptions = res.data
      })
    },
    softDelete(info) {
      this.delLoading = true
      info.status="1"
      this.formData = info
      this.params = JSON.stringify(this.formData)
      updateProduct(this.params).then(res => {
        this.delLoading = false
        this.$refs[info.productId].doClose()
        this.init()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[info.productId].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
